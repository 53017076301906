body {
  background-color: rgb(15, 132, 143);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App{
  background-color: rgb(219,219,219);
  margin: 20px auto;
  width: 90%;
  max-width: 800px;
  border-radius: 10px;
}

.navbar{
  padding: 5px;
  text-align: center;
  border-radius: 10px;
}

.navbar ul{
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  padding: 0;
}

.todo-list ul{
  padding: 0 10px 10px 10px;
  list-style: none;
}

.todo-list li{
  padding: 10px;
  cursor: pointer;
  margin: 10px 0;
  border-radius: 4px;
}

.todo-list li:hover{
  opacity: 0.7;
  text-decoration: line-through;
}

form{
  padding: 10px;
}

input[type='text']{
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 6px 0;
  border: 0;
}

input[type='submit']{
  margin: 10px auto;
  border: 0;
  display: block;
  padding: 10px;
  background-color: rgb(36, 204, 36);
}

.toggle-btn{
  text-align: center;
  padding-bottom: 10px;
}